<template>
  <right-content>
     <base-info
      editPath="company-change"
      :baseInfoList="infoDetail"
      :detailInfo="userInfo"
    ></base-info>
    <div class="resetPwd">
      <el-button @click="resetPwd">重置密码</el-button>
    </div>
  </right-content>
</template>

<script>
import BaseInfo from "@/views/BaseInfo";
import RightContent from '../commonViews/RightContent';
import { resetPassword } from "@/api";
export default {
  created() {
    const info = this.$route.query.info;
    if (info) {
      this.userInfo = JSON.parse(info);
    }
  },
  data() {
    return {
      userInfo: "",
      infoDetail: [
        {
          title: "总包部名称",
          field: "name",
        },
        {
          title: "总包部管理员",
          field: "people_name",
        },
        {
          title: "联系电话",
          field: "phone_number",
        },
         {
          title: "",
          field: "",
        },
      ],
    };
  },
   methods: {
       async resetPwd() {
      const res = await this.$open({ msg: "确定重置密码" });
      if (res) {
        if (this.userInfo.id) {
          const res = await resetPassword({
            type: 1,
            id: this.userInfo.id,
            reset_type: 2,
          });
          if (res.status === 200) {
            this.$success("重置成功");
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          }
        }
      }
    },
  },
  components: {
    BaseInfo,RightContent
  },
};
</script>

<style lang="scss" scoped>
.resetPwd {
  position: absolute;
  top: 65%;
  left: 52%;
  button {
    background-color: red;
    color: #fff;
    padding: 10px 50px;
  }
}
</style>
